<template>
    <div class="instrument-field-row" v-if="label">
        <div class="instrument-field-name">{{ label }} </div>
        <div :class="['instrument-field-value']">
            <template v-if="number && value">
                <formatted-number
                    :value="value"
                    :color="color"
                    :decimals="decimals"
                    :percents="percents"
                />
            </template>
            <template v-else-if="date">
                <formatted-date :value="value"/>
            </template>
            <template v-else-if="bool">
                <span v-if="value">Y</span>
                <span v-else>-</span>
            </template>
            <template v-else-if="linkasset && linkisin">
                <el-link @click="open_instrument_page">{{value}}</el-link>
            </template>
            <template v-else>
                {{ value || '-' }}
            </template>
            <clipboard-btn v-if="copiable" :value="value"/>
        </div>
    </div>
</template>
<script>
import ClipboardBtn from '@/components/PageComponents/ClipboardBtn'
import FormattedNumber from '@/components/Formats/FormattedNumber'
import FormattedDate from '@/components/Formats/FormattedDate'

export default {
    props: {
        label: {
            required: true
        },
        value: {
            required: true
        },
        type: {
            required: false,
            default: 'string'
        },
        decimals: {
            required: false,
            default: 0
        },
        percents: {
            type: Boolean,
            default: false,
        },
        copiable: {
            type: Boolean,
            default: false,
        },
        color: {
            type: Boolean,
            default: false
        },
        date: {
            type: Boolean,
            default: false
        },
        number: {
            type: Boolean,
            default: false
        },
        sign: {
            type: Boolean,
            default: false
        },
        bool: {
            type: Boolean,
            default: false
        },
        linkasset: {
            type: String,
        },
        linkisin: {
            type: String,
        },
    },
    components: {ClipboardBtn, FormattedNumber, FormattedDate},

    methods: {
        open_instrument_page(){
            this.$router.push('/library/instruments/'+this.linkasset+'/' + this.linkisin)
        }
    }
}
</script>
