<template>
    <div class="instrument-page" v-if="instrument">
        <div class="instrument-page-sidebar">
            <div class="instrument-short-title">{{ instrument.name }}</div>
            <div class="instrument-full-title">{{ instrument.issuer_name }}</div>
            <div class="instrument-description" v-if="instrument.description">
                {{instrument.description}}
            </div>
            <div class="instrument-fields">
                <instrument-field
                    :copiable="true"
                    :label="get_field_name('isin', 'ISIN') + ':'"
                    :value="instrument.isin"/>
                <instrument-field
                    :label="get_field_name('name') + ':'"
                    :value="instrument.name"/>
                <instrument-field
                    :label="get_field_name('currency') + ':'"
                    :value="instrument.currency"/>
                <instrument-field
                    :label="get_field_name('issuer_name') + ':'"
                    :value="instrument.issuer_name"/>
                <instrument-field
                    :label="get_field_name('country') + ':'"
                    :value="instrument.country"/>
                <instrument-field
                    :label="get_field_name('issue_country_of_risk') + ':'"
                    :value="instrument.issue_country_of_risk"/>
                <instrument-field
                    :label="get_field_name('sector') + ':'"
                    :value="instrument['sector']" />
                <instrument-field
                    :label="get_field_name('industry_group') + ':'"
                    :value="instrument['industry_group']" />
                <instrument-field
                    :label="get_field_name('coupon_rate') + ':'"
                    :value="instrument.coupon_rate"/>
                <instrument-field
                    :label="get_field_name('coupon_type') + ':'"
                    :value="instrument.coupon_type"/>
                <instrument-field
                    :label="get_field_name('maturity') + ':'"
                    :value="instrument.maturity"
                    date/>
                <instrument-field
                    :label="get_field_name('last_bond_price') + ':'"
                    :value="instrument.last_bond_price"/>
                <instrument-field
                    :label="get_field_name('sinkfactor', 'Sinkfactor') + ':'"
                    :value="instrument.sinkfactor"/>
                <instrument-field
                    :label="get_field_name('source_date', 'Source Date') + ':'"
                    :value="instrument.source_date"
                    date/>
            </div>
        </div>
        <div class="instrument-page-body">
            <div class="instrument-fields">
                <instrument-field
                    :label="get_field_name('company_name') + ':'"
                    :value="instrument.company_name"/>
                <instrument-field
                    :label="get_field_name('region') + ':'"
                    :value="instrument.region"/>
                <instrument-field
                    :label="get_field_name('bond_ac_code') + ':'"
                    :value="instrument.bond_ac_code"/>
                <instrument-field
                    :label="get_field_name('bond_issue_date') + ':'"
                    :value="instrument.bond_issue_date"
                    date/>
                <instrument-field
                    :label="get_field_name('payment_rank') + ':'"
                    :value="instrument.payment_rank"/>
                <instrument-field
                    :label="get_field_name('ask_price') + ':'"
                    :value="instrument.ask_price"
                    number
                    :decimals="4"/>
                <instrument-field
                    :label="get_field_name('ask_price_previous_close') + ':'"
                    :value="instrument.ask_price_previous_close"
                    number
                    :decimals="4"/>
                <instrument-field
                    :label="get_field_name('accrued_coupon') + ':'"
                    :value="instrument.accrued_coupon"
                    number
                    :decimals="4"/>
                <instrument-field
                    :label="get_field_name('estimated_coupon') + ':'"
                    :value="instrument.estimated_coupon"
                    number
                    :decimals="4"/>
                <instrument-field
                    :label="get_field_name('duration') + ':'"
                    :value="instrument.duration"/>
                <instrument-field
                    :label="get_field_name('accrual_basis') + ':'"
                    :value="instrument.accrual_basis"/>
                <instrument-field
                    :label="get_field_name('issuer_company_id') + ':'"
                    :value="instrument.issuer_company_id"/>
                <instrument-field
                    :label="get_field_name('org_id') + ':'"
                    :value="instrument.org_id"/>
                <instrument-field
                    :label="get_field_name('company_id') + ':'"
                    :value="instrument.company_id"/>
                <instrument-field
                    :label="get_field_name('moody_rating') + ':'"
                    :value="instrument.moody_rating"/>
                <instrument-field
                    :label="get_field_name('fitch_rating') + ':'"
                    :value="instrument.fitch_rating"/>
                <instrument-field
                    :label="get_field_name('s&p_rating') + ':'"
                    :value="instrument['s&p_rating']"/>
                <instrument-field
                    :label="get_field_name('amt_out') + ':'"
                    :value="instrument.amt_out"/>
                <instrument-field
                    :label="get_field_name('industry_group') + ':'"
                    :value="instrument.industry_group"/>
                <instrument-field
                    :label="get_field_name('sector') + ':'"
                    :value="instrument.sector"/>
                <instrument-field
                    :label="get_field_name('z-spread') + ':'"
                    :value="instrument['z-spread']"/>
                <instrument-field
                    :label="get_field_name('callable_indicator') + ':'"
                    :value="instrument['callable_indicator']"/>
                <instrument-field
                    :label="get_field_name('currently_callable_indicator') + ':'"
                    :value="instrument['currently_callable_indicator']"/>
            </div>

            <div class="instrument-block-title">Price</div>

            <price-chart :period_to="period_to" :period_from="period_from" :asset="asset" :isin="isin" ref="PriceChart" :pageblock="false"/>

            <div class="instrument-fields">
                <instrument-field
                    :label="get_field_name('last_bond_price') + ':'"
                    :value="instrument.last_bond_price"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('weekly_change') + ':'"
                    :value="instrument['weekly_change']"
                    color number percents sign :decimals="4"/>
                <instrument-field
                    :label="get_field_name('monthly_change') + ':'"
                    :value="instrument['monthly_change']"
                    color number percents sign :decimals="4"/>
                <instrument-field
                    :label="get_field_name('mtd_bond_return') + ':'"
                    :value="instrument['mtd_bond_return']"
                    color number percents sign :decimals="4"/>
                <instrument-field
                    :label="get_field_name('3_months_price_change') + ':'"
                    :value="instrument['3_months_price_change']"
                    color number percents sign :decimals="4"/>
                <instrument-field
                    :label="get_field_name('6_months_price_change') + ':'"
                    :value="instrument['6_months_price_change']"
                    color number percents sign :decimals="4"/>
                <instrument-field
                    :label="get_field_name('1_year_price_change') + ':'"
                    :value="instrument['1_year_price_change']"
                    color number percents sign :decimals="4"/>
                <instrument-field
                    :label="get_field_name('ytd_bond_return') + ':'"
                    :value="instrument['ytd_bond_return']"
                    color number percents sign :decimals="4"/>
                <instrument-field
                    :label="get_field_name('1y_min_bond_price') + ':'"
                    :value="instrument['1y_min_bond_price']"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('1y_max_bond_price') + ':'"
                    :value="instrument['1y_max_bond_price']"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('1y_st_dev_bond_price') + ':'"
                    :value="instrument['1y_st_dev_bond_price']"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('z_score_1_year') + ':'"
                    :value="instrument['z_score_1_year']"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('percentile_1_year') + ':'"
                    :value="instrument['percentile_1_year']"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('yield') + ':'"
                    :value="instrument['yield']"/>
                <instrument-field
                    :label="get_field_name('g-spread') + ':'"
                    :value="instrument['g-spread']"
                    number :decimals="4"/>
                <instrument-field
                    :label="get_field_name('sector_yield_mean') + ':'"
                    :value="instrument['sector_yield_mean']"
                    number :decimals="4"/>
            </div>

            <template v-if="subdata && subfields && subfields.length">
                <div class="instrument-block-title">Emitent</div>

                <div class="instrument-fields">
                    <instrument-field
                        :label="get_subfield_name('isin', 'ISIN') + ':'"
                        :value="subdata['isin']"
                        linkasset="equity"
                        :linkisin="subdata['isin']" />
                    <instrument-field
                        :label="get_subfield_name('company_name') + ':'"
                        :value="subdata['company_name']" />
                    <instrument-field
                        :label="get_subfield_name('public') + ':'"
                        :value="subdata['public']"
                        bool />
                    <instrument-field
                        :label="get_subfield_name('company_id') + ':'"
                        :value="subdata['company_id']" />
                    <instrument-field
                        :label="get_subfield_name('ticker') + ':'"
                        :value="subdata['ticker']" />
                    <instrument-field
                        :label="get_subfield_name('cash') + ':'"
                        :value="subdata['cash']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('market_cap_mln') + ':'"
                        :value="subdata['market_cap_mln']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('net_debt_mln') + ':'"
                        :value="subdata['net_debt_mln']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('ev_mln') + ':'"
                        :value="subdata['ev_mln']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('tier_1_capital') + ':'"
                        :value="subdata['tier_1_capital']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('fcf_12m') + ':'"
                        :value="subdata['fcf_12m']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('ebitda_margin') + ':'"
                        :value="subdata['ebitda_margin']"
                        number :decimals="4" percents />
                    <instrument-field
                        :label="get_subfield_name('sales') + ':'"
                        :value="subdata['sales']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('int_coverage') + ':'"
                        :value="subdata['int_coverage']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('altman_z_score_man') + ':'"
                        :value="subdata['altman_z_score_man']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('p_b') + ':'"
                        :value="subdata['p_b']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('p_e') + ':'"
                        :value="subdata['p_e']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('roe') + ':'"
                        :value="subdata['roe']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('cost_income') + ':'"
                        :value="subdata['cost_income']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('net_interest_margin') + ':'"
                        :value="subdata['net_interest_margin']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('loan_loss_provisions') + ':'"
                        :value="subdata['loan_loss_provisions']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('fcf') + ':'"
                        :value="subdata['fcf']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('fcf_l1') + ':'"
                        :value="subdata['fcf_l1']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('fcf_l2') + ':'"
                        :value="subdata['fcf_l2']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('fcf_f1') + ':'"
                        :value="subdata['fcf_f1']"
                        number :decimals="4" />
                    <instrument-field
                        :label="get_subfield_name('fcf_f2') + ':'"
                        :value="subdata['fcf_f2']"
                        number :decimals="4" />
                </div>
            </template>

            <div class="instrument-block-title">Coupons</div>
            <vxe-table
                :data="coupons"
                stripe
                border="inner"
                size="small">
                <vxe-column field="pay_date" title="Pay Date" width="120">
                    <template #default="{ row }">
                        <formatted-date :value="row.pay_date"/>
                    </template>
                </vxe-column>
                <vxe-column field="pay_value" title="Pay Value"></vxe-column>
            </vxe-table>
        </div>
    </div>
</template>

<script>
import PriceChart from '@/components/PriceChart'
import InstrumentField from "@/components/InstrumentField";
import FormattedDate from '@/components/Formats/FormattedDate'

export default {
    components: {PriceChart, InstrumentField, FormattedDate},
    props: {
        isin: {
            required: true,
        },
    },

    data() {
        return {
            instrument  : undefined,
            fields      : undefined,
            period_from : this.$moment().add({years:-1}).toDate(),
            period_to   : this.$moment().toDate(),
            asset       : 'bond',
            subfields   : undefined,
            subdata     : undefined,
            coupons     : [],
        }
    },

    mounted() {
        if (this.asset && this.isin) {
            this.$store.dispatch('instrument/getInstrumentFull', {
                isin: this.isin,
                asset: this.asset
            }).then((instrument_response) => {
                this.instrument = instrument_response.instrument
                this.fields = instrument_response.fields
                this.subfields = instrument_response.subfields || []
                this.subdata = instrument_response.subdata || {}
                this.$emit('tab-title-change', this.instrument.name)

                this.$store.dispatch('instrument/getBondCouponCalendar', {isin: this.isin}).then(coupon_response => {
                    this.coupons = coupon_response
                })
            })
        }
    },

    methods: {
        get_field_name(field, default_name=''){
            let field_name = ''

            this.fields.forEach(item => {
                if(item.orkl_name === field)
                    field_name = item.app_name
            })

            if(!field_name && default_name) field_name = default_name

            return field_name
        },
        get_subfield_name(field, default_name=''){
            let field_name = ''

            this.subfields.forEach(item => {
                if(item.orkl_name === field)
                    field_name = item.app_name
            })

            if(!field_name && default_name) field_name = default_name

            return field_name
        },
    }
}
</script>
